<template>
  <div class="gradient-bg flex min-h-[250px] justify-end pb-24 lg:min-h-[350px] lg:pb-70">
    <WidthWrapper class="flex w-full flex-col justify-end gap-12 lg:gap-24">
      <h1 class="typo-3xl lg:typo-7xl font-bold text-white">
        {{ page.title }}
      </h1>
      <ProfileNavigation
        :links="page.links"
      />
    </WidthWrapper>
  </div>
  <div class="my-64 lg:my-128 ">
    <WidthWrapper>
      <slot />
    </WidthWrapper>
  </div>
</template>

<script lang="ts" setup>
import { SelfServiceSnippetTransformer } from '@aller/nuxt-allerservice-sdk/transformers/pages/selfservice/SelfServiceSnippetTransformer';
import { SelfServerviceOverviewTransformer } from '@aller/nuxt-allerservice-sdk/transformers';

const page = await useOptimizely().content().byPath('mitaller', ['*']).then((data) => SelfServerviceOverviewTransformer(data.value));
const data = await useOptimizely().content().byPath('mitaller', ['*']);

useState('snippet', () => SelfServiceSnippetTransformer(data.value));
</script>

<style scoped>
.gradient-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(77deg, #3C5C70 9.77%, #00C2A2 83.67%);
}
</style>
