<template>
  <div>
    <ul class="hidden gap-12 lg:flex lg:flex-wrap">
      <li v-for="link in links" :key="link.route">
        <NuxtLink
          :to="link.route"
          class="rounded-lg px-16 py-10 font-semibold text-white transition-all duration-300"
          :class="{ 'bg-black/20': route.fullPath === link.route }"
        >
          {{ link.label }}
        </NuxtLink>
      </li>
    </ul>
    <select :value="route.fullPath" class="form-select w-full rounded-lg border-none bg-black/20 p-16 font-semibold text-white lg:hidden" @change="changePage">
      <option v-for="link in links" :key="link.route" :selected="link.route === route.fullPath" :value="link.route">
        {{ link.label }}
      </option>
    </select>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  links: {
    type: Array as PropType<{ label: string; route: string }[]>,
    required: true,
  },
});

const route = useRoute();

const changePage = async (event: Event) => {
  await useRouter().push({ path: (event.target as HTMLSelectElement).value });
};
</script>

<style scoped>
/**
 * White arrow instead of default black.
 */
.form-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='#23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
}
</style>
